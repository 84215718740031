var render = function () {
  var _vm$book, _vm$book2, _vm$book3, _vm$book4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "book-summary-mini"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": (_vm$book = _vm.book) === null || _vm$book === void 0 ? void 0 : _vm$book.imageUrl
    }
  })])]), _c('div', {
    staticClass: "col-lg-9"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('p', [_vm._v(_vm._s((_vm$book2 = _vm.book) === null || _vm$book2 === void 0 ? void 0 : _vm$book2.title))]), _c('p', [_vm._v(_vm._s((_vm$book3 = _vm.book) === null || _vm$book3 === void 0 ? void 0 : _vm$book3.author))])]), _c('div', {
    staticClass: "details"
  }, [_c('p', [_vm._v(_vm._s((_vm$book4 = _vm.book) === null || _vm$book4 === void 0 ? void 0 : _vm$book4.description))])]), _c('div', {
    staticClass: "actions"
  }, [_c('button', {
    staticClass: "read-more",
    on: {
      "click": function ($event) {
        return _vm.openBookDetails();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/zadi/book.png")
    }
  }), _vm._v(" إقرأ المزيد ")]), _vm.book.summeryVideoUrl ? _c('button', {
    staticClass: "open-video",
    on: {
      "click": function ($event) {
        return _vm.openBookVideo();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/zadi/video.png")
    }
  }), _vm._v(" مشاهدة الفيديو ")]) : _vm._e()])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }